#Logo {
  max-width: 125px !important;
  margin-bottom: 10px !important;
  margin-top: 10px !important;

  &.bottom{
    margin-top: 4px;
    margin-bottom: 4px;
  }

  &.LEFT{
    float: left;
  }
}

.country_code{
  font-weight: 700;
}