#Button {
	margin-right: auto;
	margin-left: auto;
	width: 300px;
	max-width: 100%;
	padding: 15px 20px;
	border-radius: 30px;
	font-size: 20px;
	font-weight: 900;
	letter-spacing: 3px;
	text-transform: uppercase;
	margin-bottom: 1rem;
	box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);

	&:disabled{
		opacity: 0.7;
	}
}