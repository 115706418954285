.phoneInput{
    background: #fff;
    border: 1px solid #cacaca;
    border-radius: 5px;
    font-size: 20px;
    height: 50px;
    letter-spacing: .01rem;
    line-height: 25px;
    margin-bottom: 0!important;
    margin-left: 0;
    margin-top: 0!important;
    outline: none;
    position: relative;
    width: 200px;
    overflow: hidden;


    input[type=tel]{
        border: none;
        outline: none;
        box-shadow: none;
        padding-left: 1rem;
    }
}
.PhoneInputCountry{
    background: #f5f5f5;
    padding: 15px;
    display: none !important;
}