h1.title{
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 30px;

  @media (max-width: 360px) and (max-height: 600px) {
    font-size: 25px;
    line-height: 25px;
  }
}
.subTitle {
  font-weight: 700;
  font-size: 15px;
}

.paragraph {
  font-weight: 500;
  font-size: 12px;
  text-align: justify;
  line-height: 17px;

  &.smaller {
    font-size: 9px !important;
    line-height: 12px;
  }
}

.flow-container{
  z-index: 99;
}

.video-flow-container{
  .video-loader,
  .flow-container{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 640px;
  }
  .play-btn,
  .flow-container{
    display: none;
  }
  .video-loader{
    z-index: 99;
    display: block;
  }
  &.active {
    .video-loader{
      display: none;
    }
    .flow-container{
      display: block;
    }
  }
}

.tooltip-arrow{
  left: unset !important;
}
.carriers-btn{
  font-size: 0.6rem !important;
  padding: 10px 10px !important;
  margin: 1px;
}