
@font-face {
  font-family: 'Cairo';
  src: url('./assets/fonts/cairo/Cairo-Regular.woff2') format('woff2'),
  url('./assets/fonts/cairo/Cairo-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('./assets/fonts/cairo/Cairo-Medium.woff2') format('woff2'),
  url('./assets/fonts/cairo/Cairo-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('./assets/fonts/cairo/Cairo-ExtraBold.woff2') format('woff2'),
  url('./assets/fonts/cairo/Cairo-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('./assets/fonts/cairo/Cairo-SemiBold.woff2') format('woff2'),
  url('./assets/fonts/cairo/Cairo-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('./assets/fonts/cairo/Cairo-Light.woff2') format('woff2'),
  url('./assets/fonts/cairo/Cairo-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('./assets/fonts/cairo/Cairo-ExtraLight.woff2') format('woff2'),
  url('./assets/fonts/cairo/Cairo-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('./assets/fonts/cairo/Cairo-Bold.woff2') format('woff2'),
  url('./assets/fonts/cairo/Cairo-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('./assets/fonts/cairo/Cairo-Black.woff2') format('woff2'),
  url('./assets/fonts/cairo/Cairo-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: bold;
  src: local('Gilroy'), url('./assets/fonts/gilroy/Gilroy-Bold.woff') format('woff');
}


@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 900;
  src: local('Gilroy'), url('./assets/fonts/gilroy/Gilroy-Heavy.woff') format('woff');
}


@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 100;
  src: local('Gilroy'), url('./assets/fonts/gilroy/Gilroy-Light.woff') format('woff');
}


@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  src: local('Gilroy-Medium ☞'), url('./assets/fonts/gilroy/Gilroy-Medium.woff') format('woff');
}


@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  src: local('Gilroy'), url('./assets/fonts/gilroy/Gilroy-Regular.woff') format('woff');
}