.download-generic-video{
  &:not(.video-approach){
    .video-play-btn,
    .player-icons,
    .file-icons
    {
      display: none !important;
    }
  }
  #mainBox{
    width: 100%;
  }
  header{
    width: 100%;
    height: auto;
  }

  h1{
    &.title{
      font-size: 29px;
    }
  }
  .subTitle{
    font-size: 20px;
  }

  #Button{
    border: none;
    border-radius: 10px;
    width: auto;
    @media (max-width: 480px){
      font-size: 13px;
    }
    min-width: 320px;
  }

  .phoneInput{
    width: 320px;
  }

  .video-bg{
    background-color: black;
    min-height: 450px;
    &.blurred{
      img{
        opacity: 0.5;
        transition: opacity 0.3s;
      }
    }
    &::after{
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: 0.5;
    }
  }

  .video-play-btn{
    position: relative;
    background-color: #ffffff8f;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin: 15px auto;
    .play-icon{
      width: unset !important;
      position: absolute;
      top: 50%;
      left: 55%;
      transform: translate(-50%, -50%);
    }
    @media (max-width: 480px){
      width: 150px;
      height: 150px;
    }
  }

  // .prep-btn{
  //   background: linear-gradient(273.53deg, #7A27E4 -4.65%, #FF0302 103.66%);
  //   border-radius: 30px !important;
  // }

  .player-icons{
    width: 100%;
    max-width: 650px;
    margin-top: -10px;
    margin-bottom: 25px;
    @media (max-width: 480px){
      padding: 10px;
    }
  }

  .video-player{
    @media (max-width: 480px){
      padding: 10px;
    }
    img,
    svg:not(.checkmark){
      width: 100%;
      height: auto;
    }
  }

  $green: #04d910;
  $white: #fff;
  // Misc
  $curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);
  .checkmark {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: 25px;
    top: 10px;
    display: block;
    stroke-width: 5;
    stroke: $white;
    stroke-miterlimit: 20;
    box-shadow: inset 0px 0px 0px $green;
    animation: fill .2s ease-in-out .1s forwards, scale .1s ease-in-out .2s both;
  }
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 5;
    stroke-miterlimit: 20;
    stroke: $green;
    fill: none;
    animation: stroke .3s $curve forwards;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke .3s $curve .4s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px $green;
    }
  }

  .file-icon{
    width: 50px;
    @media (max-width: 480px){
      width: 32px
    }
  }

  footer{
    max-width: 680px;
    margin: 20px auto;
  }
}