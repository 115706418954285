#Logo {
  &.Large {
    max-width: 400px;
    margin-top: 20px;
    margin-bottom: 20px;
    &.bottom{
      max-width: 350px;
    }
  }

  &.Medium {
    max-width: 300px;
    margin-top: 20px;
    margin-bottom: 20px;
    &.bottom{
      max-width: 250px;
    }
  }

  &.Small {
    max-width: 200px;
    margin-top: 20px;
    margin-bottom: 20px;

    &.bottom{
      max-width: 150px;
    }
  }

  &.bottom{
    margin-top: 4px;
    margin-bottom: 4px;
  }

}